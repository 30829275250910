import React from 'react';

import { Card, CardTitle, CardBody, Badge } from 'reactstrap';
import Link from 'next/link';
// import { GiGrapes } from 'react-icons/gi';
import { GoLocation, GoCalendar } from 'react-icons/go';

import Image from "next/legacy/image";
import countries from '../utils/countries';
import formatMoney from '../utils/formatMoney';
import AddToBasketButton from './AddToBasketButton';

function ProductTile({ product, index }: {product: Product, index?: number}) {
  const { name, image, pricing, details, category, slug } = product;

  const isOffer = !!pricing.listingPrice && pricing.listingPrice > pricing.sellingPrice;

  const country =
    details && details.country ? countries.find(({ code }) => code === details.country) : null;
  const discount = pricing.listingPrice && pricing.listingPrice > pricing.sellingPrice;

  return (
    <Card className="productTile mb-4">
      <Link
        href="/[categoryName]/[slug]"
        as={`/${category}/${slug}`}
        className="d-block p-2 p-md-4 position-relative">

        {/* !! Problem with loading times nextjs v.10.2.0, try Image component with version change !! */}
        <img
          className="img-fluid"
          loading="lazy"
          // priority={!!(index && index < 6)} // load first 6 products eagerly
          width={400}
          height={400}
          src={image.src.small}
          alt={name}
        />
        {isOffer && (
          <Badge className="shadow-sm" color="primary">
            aanbieding
          </Badge>
        )}

      </Link>

      <CardBody className="d-flex flex-column justify-content-between">
        <div>
          <Link
            href="/[categoryName]/[slug]"
            as={`/${category}/${slug}`}
            className="font-weight-light text-decoration-none">

            <CardTitle tag="h3" className="text-uppercase mb-1 text-black">
              {name}
            </CardTitle>

          </Link>

          {country && (
            <div className="country my-1 d-flex align-items-center">
              <img
                className="d-inline-block me-2 img-fluid"
                style={{ height: 12 }}
                src={`/flags/${country.code.toLowerCase()}.svg`}
                alt=""
              />
              <span>{country.name}</span>
            </div>
          )}

          {details && details.region && details.region.name && (
            <div className="d-none my-1 region d-md-flex align-items-center">
              <GoLocation className="me-2" />
              <span>{details.region.name}</span>
            </div>
          )}
          {details && details.year && details.year && (
            <div className="year my-1 d-flex align-items-center">
              <GoCalendar className="me-2" />
              <span>{details.year}</span>
            </div>
          )}

          {/* {details && details.grapes && details.grapes.length > 0 && (
            <div className="my-1 d-flex align-items-center">
              <GiGrapes className="me-2" />
              <span>{details.grapes.join(', ')}</span>
            </div>
          )} */}
        </div>
        <div className="d-flex justify-content-between align-items-start mt-1">
          <div>
            {!!discount && (
              <span style={{ textDecoration: 'line-through' }} className="me-2">
                {`  ${formatMoney(pricing.listingPrice)}  `}
              </span>
            )}
            <strong className={`sellingPrice${discount ? ' text-primary' : ''}`}>
              {formatMoney(pricing.sellingPrice)}
            </strong>
          </div>
        </div>
        <AddToBasketButton product={product} />
      </CardBody>
    </Card>
  );
}

export default ProductTile;
